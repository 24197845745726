import React from "react";
import { masterContent } from "../../../constants/masterContent";
import ImageSection from "../ImageSection/ImageSection";
import MasterBanner from "../MasterBanner/MasterBanner";
import * as styles from './style.module.scss';
import circle from "../../../images/circle.png";
function index() {
  return (
    <div className={styles.container}>
            <MasterBanner title="Customize & Connect" span="Modules" description={"The modules and features are extensive. Here's an overview of the functionalities offered. "} />
            <div className={styles.sections}>
            <img alt="banner" src={circle}  className={`${styles.circleimg} ${styles.circleimg1}`}/>
            <img alt="banner" src={circle} className={`${styles.circleimg} ${styles.circleimg3}`}/>
            <img alt="banner" src={circle} className={`${styles.circleimg} ${styles.circleimg4}`}/>
              {masterContent.map((d,i) =>{
                return(
                  <ImageSection extraClass="modules" {...d} key={i}/>
                )
              })}
            </div>
    </div>
  );
}

export default index;
