import React from "react";
import { useEffect } from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import AOS from "aos";
import "aos/dist/aos.css";
import FreeTrial from "../components/atoms/freeTrial";
import Head from "../components/ogranisms/head";
import ModuleBanner from "../components/molecules/moduleBanner";
import { Helmet } from "react-helmet";
const Modules = () => {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Modules</title>
        <link rel="canonical" href="https://masterwizr.com/modules" />
      </Helmet>
      <Head
        title="MASTER WiZR Modules"
        description="Streaming View, Show Rooms. 3D Spaces. Cover Pages."
        ogTitle="Modules"
      />
      <main>
        <Header />
        <ModuleBanner />
        <FreeTrial />
        <Footer />
      </main>
    </>
  );
};

export default Modules;
