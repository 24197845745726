import React, { useEffect } from "react";
import BannerMText from "../../atoms/bannerMTekst";
import AOS from "aos";
import "aos/dist/aos.css";
function Index() {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  return (
        <BannerMText />
  );
}

export default Index;